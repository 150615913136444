import React from "react";
import { Modal, Button, Text } from "@mantine/core";


interface LoginErrorDialogProps {
  opened: boolean;
  onClose: () => void;
  message: string;
}

const LoginErrorDialog: React.FC<LoginErrorDialogProps> = ({ opened, onClose, message }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Error"
      centered
    >
      <Text color="red">{message}</Text>

      <Button fullWidth mt="md" color="red" onClick={onClose}>
        Close
      </Button>
    </Modal>
  );
};

export default LoginErrorDialog;
