import { Center } from "@mantine/core"
import { Form } from "formik"
import React, { ChangeEvent } from "react"
import { Modal} from '@mantine/core';
import { MantineDefaultButton as Button } from "src/components"
import { useState } from "react";
import { PasswordInput } from "@mantine/core";
import { authenticateWithUsernameAndPassword } from "src/api/stretch/integration/api/invocations";
import { useHistory } from "react-router-dom";
import {Spinner} from "src/components"
import LoginErrorDialog from "./LoginError";

import {
  Card,
  Feedback,
  Headings,
  StretchForm,
  SubmitButton,
  TextInput,
  TimedFeedback,
} from "src/components"
import { UiFeedback } from "src/types"
import * as yup from "yup"

const validationSchema = yup.object({
  email: yup.string().email().required("Please enter your email."),
})

type EmailLoginFormProps = {
  error: string
  isSubmitting: boolean
  loginWithEmail: (_email: string) => void
  uiFeedback?: UiFeedback
  setError: (_error: string) => void
}

const EmailLoginForm: React.FC<EmailLoginFormProps> = ({
  error,
  uiFeedback,
  isSubmitting,
  loginWithEmail,
  setError,
}) => {
  const { message, messageType } = uiFeedback ?? {}
  const [modalOpened, setModalOpened] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [loginErrorOpened, setLoginErrorOpened] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const history = useHistory();

  // Handle Login Submission
  const handleLogin = () => {
    console.log("Logging in with:", { username, password });
    setModalOpened(false);
    loginWithUsernameAndPassword();
  };

  function loginWithUsernameAndPassword(){

    setLoginInProgress(true);

    authenticateWithUsernameAndPassword(username,password).then(res => {

      setLoginInProgress(false);

      if(res?.success == true){
          //api call success
          if(res.data.authenticated){
            //authentication success
            const signInToken = res.data.token;
            history.push("/login/".concat(signInToken))
          }else{
            //authentication failure
            setLoginErrorMessage("Could not login, please check your username and password and try again");
            setLoginErrorOpened(true);
          }
      }else{
        //api call failure
        setLoginErrorMessage("Could not login, please try again later");
        setLoginErrorOpened(true);

      }
    })
  }

  return (
    <Card $marginAuto $page $fullPage>
      {!!message && (
        <Feedback
          message={message}
          altStyle
          $green={messageType == "positive"}
        />
      )}

      <Headings.H2>Log In</Headings.H2>

      <StretchForm
        initialValues={{
          email: "",
        }}
        onSubmit={values => {
          loginWithEmail(values.email)
        }}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({ values, errors, touched, isValid, handleChange, handleBlur }) => {
          const handleFieldChange = (
            setField: (_value: string) => void,
            value: string,
          ) => {
            setField(value)
            setError("")
          }

          return (
            <Form>
              <TextInput
                autoComplete="home email"
                name="email"
                placeholder="Enter your email"
                value={values.email}
                error={touched.email ? errors.email : undefined}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  handleFieldChange(handleChange("email"), event.target.value)
                }}
                onBlur={handleBlur("email")}
                type="email"
              />
              <SubmitButton
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                label="Continue"
              />
              <div style={{ height: "20px" }}></div>
              <Center>
              <p>
              Or{" "}
              <span 
                style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer"
                }}
                onClick={() => setModalOpened(true)}
              >
              click here
              </span> 
              {" "}to Log In with a username and password
              </p>
              </Center>
              {!!error && (
                <TimedFeedback
                  message={error}
                  timeout={3000}
                  onTimeout={() => {
                    setError("")
                  }}
                />
              )}

                {/* Login Modal */}
                <Modal
                  opened={modalOpened}
                  onClose={() => setModalOpened(false)}
                  title="Log In"
                  centered
                >

                  {/* Username Input */}
                  <TextInput
                    label="Username"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    styles={{ input: { height: "45px" } }} // Explicit height adjustment
                  />

                  {/* Password Input */}
                  <PasswordInput
                    label="Password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    styles={{ input: { height: "45px" } }} // Explicit height adjustment
                  />

                  {/* Login Button */}
                  <Button
                    fullWidth
                    style={{ marginTop: "10px", height: "45px" }} // Ensuring button height matches inputs
                    onClick={handleLogin}
                    label="Continue"
                  >
                  </Button>
                </Modal>

                {/* Error Dialog */}
                <LoginErrorDialog
                  opened={loginErrorOpened}
                  onClose={() => setLoginErrorOpened(false)}
                  message={loginErrorMessage}
                />
                
            </Form>
          )
        }}
        
      </StretchForm>

      {loginInProgress && <Spinner></Spinner>}
      
    </Card>
    
  )
}

export default EmailLoginForm
